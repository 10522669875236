import React, { Component, Fragment } from "react";
import { slideSlick } from "../page-demo/script";
import { FiChevronUp } from "react-icons/fi";
import { API_URL } from '../component/constant/constant';
import Slider from "react-slick";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ServiceTwo from "../elements/service/ServiceTwo";
import Testimonial from "../elements/Testimonial";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Helmet from "../component/common/Helmet";
import axios from 'axios';

class Startup extends Component {
    constructor(props) {
        super()
        this.state = {
            slider: [],
            blogs: []
        };
    }

    getSliderData() {
        axios.post(API_URL.SITE_SLIDERS, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, slider: res.data.data
                }))
            }
        });
    }

    getBlogData() {
        axios.post(API_URL.SITE_NEWS, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, blogs: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getSliderData();
        this.getBlogData();
    }


    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Home" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="slider-wrapper">
                    <div className="slider-activation slider-startup">
                        {this.state.slider.length > 0 &&
                            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                                {this.state.slider.map((value, index) => (
                                    <div key={index} data-black-overlay="10">
                                        <div className="slide slide-style-1 fullscreen d-flex align-items-center justify-content-center bg_image" style={{ backgroundImage: `url(${value.image})` }}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="inner text-center">
                                                        {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                        {value.sub_title ? <p className="description" style={{ color: 'white' }}>{value.sub_title}</p> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>}
                    </div>
                </div >
                <div className="about-area ptb--120">
                    <AboutTwo />
                </div>
                <div className="service-area ptb--75  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>Our Products</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                    </div>
                </div>
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                <div className="rn-blog-area pt--120 bg_color--1">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            {this.state.blogs.map((value, i) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={`/blog-details/${value.slug}`}>
                                                <img className="w-100" src={value.image} height={350} alt="Blog Images" />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><a href={`/blog-details/${value.slug}`}>{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={`/blog-details/${value.slug}`}>Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </Fragment >
        )
    }
}
export default Startup;