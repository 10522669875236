import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { API_URL } from '../component/constant/constant';
import axios from 'axios';


class Export extends Component {

    constructor(props) {
        super()
        this.state = {
            countries: []
        };
    }

    getCountriesData() {
        axios.post(API_URL.SITE_COUNTRIES, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, countries: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getCountriesData();
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Export' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17 bg_image_exports">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Exports to Countries</h2>
                                    {/* <p>We create world class products, that caters to India and over 0 countries across the globe.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                    <p>At Lovit Bathware, exports are spread across all over the globe with prime focus on niche markets of Middle East and Africa we are spreading our wings and delivering the best products globally.</p>
                    <p>Also at Lovit Bathware, the main aim is to help a business set up and establish their network of clients and generating additional revenue by reducing complications associated with it.</p>
                    <p>Lovit Bathware Dispatch Centre is located about 200 kms from Mundra port, the largest port in India. Well connected, the port is easily accessible by road and its geographical proximity and easy connectivity ensure minimum time lag for a shipment.</p>
                    <p className="mb-5">The port of Mundra is frequented by all major shipping lines and provides flexibility in choice of lines that can be used to send freights across the world without any difficulties as well as on time.</p>
                        <ul className={`brand-style-2`}>
                            {this.state.countries.map((element, key) => {
                                return (
                                    <li>
                                        <img style={{borderRadius: '5px'}} src={element.image}  alt="country Images" />
                                        <h4 className="title mt-2">{element.name}</h4>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default Export