import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { API_URL } from '../constant/constant';
import axios from 'axios';

class Footer extends Component {

    constructor(props) {
        super()
        this.state = {
            contacts: []
        };
    }

    getContactsData() {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, contacts: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getContactsData();
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-4 col-sm-4 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/portfolio">Products</a></li>
                                                    <li><a href="/about">About</a></li>
                                                    <li><a href="/blog">News</a></li>
                                                    <li><a href="/catalogue">Catalogue</a></li>
                                                    <li><a href="/export">Export</a></li>
                                                    <li><a href="/contact">Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-8 col-sm-8 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Address</h4>
                                                <ul className="ft-link">
                                                    <li><a>{this.state.contacts.address_one}</a></li>
                                                    <li><a>{this.state.contacts.address_two}</a></li>
                                                    <li><a href={`mailto:${this.state.contacts.contact_email}`}>{this.state.contacts.contact_email}</a></li>
                                                    <li><a href={`tel:${this.state.contacts.mobile_number}`}>{this.state.contacts.mobile_number}</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {this.state.contacts.facebook && <li><a target="_blank" rel="noopener noreferrer" href={`${this.state.contacts.facebook}`}><FaFacebookF /></a></li>}
                                                        {this.state.contacts.linkedin && <li><a target="_blank" rel="noopener noreferrer" href={`${this.state.contacts.linkedin}`}><FaLinkedinIn /></a></li>}
                                                        {this.state.contacts.instagram && <li><a target="_blank" rel="noopener noreferrer" href={`${this.state.contacts.instagram}`}><FaInstagram /></a></li>}
                                                        {this.state.contacts.twitter && <li><a target="_blank" rel="noopener noreferrer" href={`${this.state.contacts.twitter}`}><FaTwitter /></a></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2024 Lovit Bathware. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;