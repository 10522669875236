import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { API_URL } from '../component/constant/constant';
import axios from 'axios';

class Testimonial extends Component {

    constructor(props) {
        super()
        this.state = {
            testimonial: []
        };
    }

    getTestimonialData() {
        axios.post(API_URL.SITE_TESTIMONIAL, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, testimonial: res.data.data
                }))
            }
        });
    }


    componentDidMount() {
        this.getTestimonialData();
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            {this.state.testimonial.map((element, key) => {
                                return (
                                    <TabPanel key={key}>
                                        <div className="rn-testimonial-content text-center">
                                            <div className="inner">
                                                <p>{element.review}</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>{element.client_name} </span> - {element.client_address}</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                )
                            })}
                            <TabList className="testimonial-thumb-wrapper">
                                {this.state.testimonial.map((element, key) => {
                                    return (
                                        <Tab key={key}>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src={element.image} alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                    );
                                })}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;