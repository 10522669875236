import React, { Component } from "react";
import { FiChevronUp } from "react-icons/fi";
import { API_URL } from '../component/constant/constant';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import ServiceTwo from "../elements/service/ServiceTwo";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import axios from 'axios';

class About extends Component {
    constructor(props) {
        super()
        this.state = {
            teams: []
        };
    }

    getTeamsData() {
        axios.post(API_URL.SITE_TEAM, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, teams: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        // this.getTeamsData();
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='About' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <Breadcrumb title={'About'} />
                <div className="rn-about-area ptb--120 bg_color--1">
                    <AboutTwo />
                </div>
                <div className="service-area ptb--75  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer /> 
            </React.Fragment>
        )
    }
}
export default About