import React, { Component } from "react";
import { FiAperture, FiLayers, FiUsers, FiCodepen, FiMonitor } from "react-icons/fi";
import innovationIcon from '../../img/innovation.png';
import qualityIcon from '../../img/quality.png';
import integrityIcon from '../../img/integrity.png';
import customerIcon from '../../img/customer.png';
import globalIcon from '../../img/global_outlook.png';
import teamworkIcon from '../../img/teamwork.png';

const ServiceList = [
    {
        icon: innovationIcon,
        title: 'Innovation',
        description: 'There is a growing preference for digitally printed tiles as their intricate designs help improve the aesthetic appeal of the house / office space.'
    },
    {
        icon: qualityIcon,
        title: 'Quality',
        description: 'Before your product is delivered, it passes through multiple quality processes that ensure you get best quality tiles to install in your properties.'
    },
    {
        icon: integrityIcon,
        title: 'Integrity',
        description: 'The wide selection of products helps you in customise according to your needs and requirements.'
    },
    {
        icon: globalIcon,
        title: 'Technological Infrastructure',
        description: 'All of our machinery and manufacturing equipments are imported from Italy.'
    },
    {
        icon: teamworkIcon,
        title: 'Team Work',
        description: 'Our growing number of enthusiasts and affiliates spread in various countries ensure your cargo reaches its destination in a hassle free way'
    },
    {
        icon: customerIcon,
        title: 'Customer Service',
        description: 'Customer service is the individual or team activity of providing proactive and prompt support to customers.'
    },
]

class ServiceTwo extends Component {
    render() {
        let title = 'Core Value',
            description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                        </div>
                    </div>
                    <div className="col-lg-12 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-lg-4 col-md-4 col-sm-4 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img src={val.icon} alt={val.title} width={ (val.title == 'Customer Service') ? 70: 50} />
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
