import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { API_URL } from '../component/constant/constant';
import axios from 'axios';


class Catalogue extends Component {

    constructor(props) {
        super()
        this.state = {
            catalogue: []
        };
    }

    getCatalogueData() {
        axios.post(API_URL.SITE_PDFS, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, catalogue: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getCatalogueData();
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Catalogue' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17 bg_image_catalogues">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Catalogues</h2>
                                    {/* <p>We are providing latest calalogues for each and every product's !</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            {this.state.catalogue.map((value, i) => (
                                <div className="col-md-3" key={i}>
                                    <a href={value.pdf_url} target='_blank' rel="noreferrer">
                                        <div className="team">
                                            <div className="thumbnail">
                                                <img src={value.pdf_img_url} alt="Blog Images" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{value.name}</h4>
                                                <p className="designation">{value.category_name}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default Catalogue