
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PageScrollTop from './component/PageScrollTop';
import Startup from './home/Startup';
import About from "./elements/About";
import Contact from "./elements/Contact";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import Catalogue from "./elements/Catalogue";
import Export from './elements/Export';
import Products from './elements/Products';
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Startup}/>
            
                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details/:slug`} component={BlogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/catalogue`} component={Catalogue}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/export`} component={Export}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/products/:slug`} component={Products}/>
                        

                        {/* Blocks Elements  */}
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();