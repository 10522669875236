import React, { Component, useParams } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import { API_URL } from '../component/constant/constant';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import axios from 'axios';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Products extends Component {

    constructor(props) {
        super()
        this.state = {
            category: {},
            product: [],
            isBusy: true,
            slug: ""
        };
    }

    getCategoryData() {
        axios.post(API_URL.SITE_SHOW_CATEGORY, { slug: this.props.match.params.slug }).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState,
                    category: res.data.data
                }));
                this.getProductsData(res.data.data.id);
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    category: {},
                    isBusy: false
                }))
            }
        });
    }

    getProductsData(id) {
        let param = {
            category_id: id
        }
        axios.post(API_URL.SITE_PRODUCT, param).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState,
                    product: res.data.data.data
                }));
            }
        })
    }

    componentDidMount() {
        this.getCategoryData();
    }

    componentDidUpdate(prevProps) {
        const { slug } = this.props.match.params;
        if (prevProps.match.params.slug !== slug) {
            this.setState((prevState) => ({
                ...prevState,
                slug: slug
            }), () => {
                this.getCategoryData();
            });
        }
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle={ (this.state.category?.name) ? this.state.category?.name : 'Products' }  />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17 bg_image_product">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{this.state.category?.name}</h2>
                                    {/* <p>{this.state.category?.description}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt--50 mb--50 product-list">
                        {this.state.product.map((item, key) => {
                            return (
                                <div className="col-md-3 product-box" key={key}>
                                    <div className="item">
                                        <div className="img-box">
                                            <img src={item.images[0].image} style={{ maxWidth: '100%' }} />
                                        </div>
                                        <div className="cont">
                                            <div className="product-title">{item.name}</div>
                                            <div className="product-category">{item.category.name}</div>
                                            <div class="product-detail"><div>{item.size.name}</div>
                                                {/* <div>Glossy</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {
                            (!this.state.isBusy && !this.state.products.length) ? <h4 className='text-center mt-5'>Product Not Found</h4> : ""
                        }
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default Products