import React, { Component } from "react";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import { API_URL } from '../component/constant/constant';
import PageHelmet from "../component/common/Helmet";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import axios from 'axios';

class Contact extends Component {
    constructor(props) {
        super()
        this.state = {
            contacts: []
        };
    }

    getContactsData() {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, contacts: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getContactsData();
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17 bg_image_contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact Us</h2>
                                    {/* <p>We are always happy to help get in touch with us today !</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact With Phone Number</h4>
                                        <p><a href={`tel:${this.state.contacts.mobile_number}`}>{this.state.contacts.mobile_number}</a></p>
                                        <p><a href={`tel:${this.state.contacts.extra_mobile_number}`}>{this.state.contacts.extra_mobile_number}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href={`mailto:${this.state.contacts.contact_email}`}>{this.state.contacts.contact_email}</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>{this.state.contacts.address_one} <br /> {this.state.contacts.address_two}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact