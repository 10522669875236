import React, { Component, Fragment } from "react";
import { API_URL } from '../../component/constant/constant';
import axios from 'axios';

class BLogList extends Component {

    constructor(props) {
        super()
        this.state = {
            blogs: []
        };
    }

    getBlogData() {
        axios.post(API_URL.SITE_NEWS, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, blogs: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getBlogData();
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    {this.state.blogs.map((value, i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={`/blog-details/${value.slug}`}>
                                        <img className="w-100" src={value.image} height={350} alt="Blog Images" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h4 className="title"><a href={`/blog-details/${value.slug}`}>{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href={`/blog-details/${value.slug}`}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}
export default BLogList;