import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_URL } from '../../component/constant/constant';
import axios from 'axios';

class PortfolioList extends Component {

    constructor(props) {
        super()
        this.state = {
            category: []
        };
    }

    getCategoryData() {
        axios.post(API_URL.SITE_CATEGORY, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, category: res.data.data
                }))
            }
        });
    }


    componentDidMount() {
        this.getCategoryData();
    }

    render() {
        const { column, styevariation } = this.props;
        return (
            <React.Fragment>
                {this.state.category.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail`} style={{ backgroundImage: `url(${value.image})` }} ></div>
                                <div className={`bg-blr-image`} style={{ backgroundImage: `url(${value.image})` }}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={`/products/${value.slug}`}>{value.name}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`/products/${value.slug}`}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={`/products/${value.slug}`}></Link>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;