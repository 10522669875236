import React, { Component, useParams } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { API_URL } from '../component/constant/constant';
import axios from 'axios';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class BlogDetails extends Component {


    constructor() {
        super()
        this.state = {
            blogs: {}
        }
    }

    getBlogData() {
        axios.post(API_URL.SITE_NEWS_BY_SLUG, {
            slug: this.props.match.params.slug
        }).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, blogs: res.data.data
                }))
            }
        });
    }

    componentDidMount() {
        this.getBlogData();
    }


    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='News Details' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{this.state.blogs?.title}</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />{this.state.blogs?.date}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rn-blog-details pt--70 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--70">
                                <div className="thumbnail">
                                    <img src={this.state.blogs?.image} alt="Blog Images" />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div dangerouslySetInnerHTML={{ __html: this.state.blogs?.description }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default BlogDetails;