import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
import { API_URL } from '../../component/constant/constant';
import axios from 'axios';


class Header extends Component {

    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        window.addEventListener('load', function () {
        });
        this.state = {
            category: []
        };
    }

    componentDidMount() {
        this.getCategoryData();
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    getCategoryData() {
        axios.post(API_URL.SITE_CATEGORY, {}).then(res => {
            if (res.data.status === 1) {
                this.setState((prevState) => ({
                    ...prevState, category: res.data.data
                }))
            }
        });
    }

    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    if (this.parentElement.querySelector('.submenu')) {
                        this.parentElement.querySelector('.submenu').classList.toggle("active");
                        this.classList.toggle("open");
                    }
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        logoUrl = <img src="/assets/images/logo/logo.png" width={180} alt="Lovit Bathware" />;
        return (
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about" >About</Link>
                                </li>
                                <li className="has-droupdown">
                                    <Link to="#pages">Product</Link>
                                    <ul className="submenu">
                                        {this.state.category.map((element, key) => {
                                            return (
                                                <li key={key}><Link to={`/products/${element.slug}`}>{element.name}</Link></li>
                                            )
                                        })}
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/catalogue" >Catalogue</Link>
                                </li>
                                <li>
                                    <Link to="/export" >Exports</Link>
                                </li>
                                <li>
                                    <Link to="/blog" >News</Link>
                                </li>
                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;